import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useNuxtApp } from '#app'
import focusable from 'focusable'
import { useModalStore } from '~/stores/modal'

/*
  Usage:
    Specify the modal name within the data.modalName
    property (e.g., 'modal', 'contact', etc.).
*/
export function useAccessibleModal(modalName: string) {
  const { $modal } = useNuxtApp()
  const modalStore = useModalStore()

  const focusBackup = ref<HTMLElement | null>(null)
  const focusableElements = ref<NodeListOf<HTMLElement> | []>([])

  const modalOpen = computed(() => modalStore.getModal(modalName))

  watch(modalOpen, (open) => {
    if (open) {
      handleOpen()
    }
    toggleScrollOnBody(open)
  })

  const handleOpen = () => {
    if (!modalOpen.value) return

    // 1. Backup current focused element.
    backupFocus()
    // 2. Map all focusable elements within the modal.
    mapFocusableElements()
    // 3. Focus the first focusable element within the modal.
    setInitialFocus()
    document.addEventListener('keyup', handleKeyDown)
  }

  const handleClose = () => {
    $modal.close(modalName)
    $modal.setInitialFields({})
    // 4. Restore focus position.
    restoreFocus()
    document.removeEventListener('keyup', handleKeyDown)
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') handleClose()
    if (e.key === 'Tab') trapFocus(e)
  }

  const backupFocus = () => {
    focusBackup.value = document.activeElement as HTMLElement
  }

  const mapFocusableElements = () => {
    focusableElements.value = document.querySelectorAll(focusable)
  }

  const setInitialFocus = () => {
    // We don't know for sure the element of the first focusable element.
    // For that reason, we try to find the first input element, and we focused
    // after that.
    let firstFocusableInput: HTMLElement | null = null
    for (const element of focusableElements.value) {
      if (
        ['input', 'textarea', 'button'].includes(element.tagName.toLowerCase())
      ) {
        firstFocusableInput = element
        break
      }
    }
    firstFocusableInput?.focus()
  }

  const trapFocus = (e: KeyboardEvent) => {
    if (!focusableElements.value.length) return

    const lastEl = focusableElements.value[focusableElements.value.length - 1]
    const firstEl = focusableElements.value[0]
    const activeEl = document.activeElement as HTMLElement

    // Tab backwards
    if (e.shiftKey && activeEl === firstEl) {
      lastEl.focus()
      e.preventDefault()
    }

    // Tab forward
    if (!e.shiftKey && activeEl === lastEl) {
      firstEl.focus()
      e.preventDefault()
    }
  }

  const restoreFocus = () => {
    focusBackup.value?.focus()
  }

  const toggleScrollOnBody = (open: boolean) => {
    setTimeout(() => {
      document.body.classList[open ? 'add' : 'remove']('container-app')
    }, 100)
  }

  onMounted(() => {
    if (!modalOpen.value) return
    handleOpen()
  })

  onUnmounted(() => {
    document.removeEventListener('keyup', handleKeyDown)
  })

  return {
    handleClose,
    modalOpen,
    handleKeyDown,
  }
}
